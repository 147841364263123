var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('StepFrame',{attrs:{"step":0}},[_c('a-spin',{staticClass:"frame-content",style:({ margin: _vm.$route.query.iniframe ? '0' : '10px' }),attrs:{"spinning":_vm.loading}},[_c('div',{staticClass:"header"},[_c('a-divider',{attrs:{"type":"vertical"}}),_c('h3',{staticClass:"title"},[_vm._v("基本信息")])],1),_c('div',{staticClass:"item"},[_c('h4',{staticClass:"sub-title"},[_vm._v("实验类型")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"type-item1"},[_c('img',{attrs:{"src":require("@/assets/images/type-item1.png"),"width":"80","height":"80"}}),_c('div',{staticStyle:{"flex":"1"}},[_c('h4',[_vm._v("UI/UX可视化实验")]),_c('span',[_vm._v(" 通过可视化编辑的方式修改已有页面元素，无需代码编程即可快速进行文本、图片的替换和布局样式的调整，并根据自定义的属性和水平生成全部可能的组合页面。目前支持Web和H5页面 ")])])]),_c('div',{staticClass:"type-item2"},[_c('img',{attrs:{"src":require("@/assets/images/type-item2.png"),"width":"80","height":"80"}}),_c('div',{staticStyle:{"flex":"1"}},[_c('h4',[_vm._v("产品原型可视化实验")]),_c('span',[_vm._v(" 通过可视化编辑的方式修改已有页面元素，无需代码编程即可快速进行文本、图片的替换和布局样式的调整，并根据自定义的属性和水平生成全部可能的组合页面。目前支持Web和H5页面 ")])])])])]),_c('a-form',{staticStyle:{"padding":"0 24px"},attrs:{"form":_vm.form,"label-col":{ span: 3 },"wrapper-col":{ span: 12 },"labelAlign":"left"}},[_c('a-divider'),_c('a-form-item',{attrs:{"label":"实验名称"}},[_c('a-input-group',{staticStyle:{"display":"inline-block","width":"auto"},attrs:{"compact":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入实验名称',
                  } ],
              } ]),expression:"[\n              'name',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入实验名称',\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"300px"},attrs:{"placeholder":"请输入实验名称"}})],1)],1),_c('a-divider'),_c('a-form-item',{attrs:{"label":"实验URL"}},[_c('a-input-group',{staticStyle:{"display":"inline-block","width":"auto"},attrs:{"compact":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'url',
              {
                rules: [
                  { required: true, message: '请输入实验URL' },
                  { pattern: /https?:\/\/.+/, message: '请输入正确的URL格式' },
                  { validator: _vm.checkUrlService } ],
              } ]),expression:"[\n              'url',\n              {\n                rules: [\n                  { required: true, message: '请输入实验URL' },\n                  { pattern: /https?:\\/\\/.+/, message: '请输入正确的URL格式' },\n                  { validator: checkUrlService },\n                ],\n              },\n            ]"}],staticStyle:{"width":"540px"},attrs:{"placeholder":"请输入实验URL"}}),_c('a-button',{attrs:{"disabled":!_vm.form.getFieldValue('url')},on:{"click":_vm.checkUrl}},[_vm._v("解析")]),_c('br'),(_vm.canSave)?_c('span',{staticStyle:{"color":"#52c41a","margin-top":"2px"}},[_vm._v("解析成功")]):_vm._e()],1)],1),_c('a-divider'),_c('a-form-item',{attrs:{"label":"备注（选填）"}},[_c('a-input-group',{staticStyle:{"display":"inline-block","width":"auto"},attrs:{"compact":""}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'remark',
              {
                rules: [],
              } ]),expression:"[\n              'remark',\n              {\n                rules: [],\n              },\n            ]"}],staticStyle:{"width":"600px"},attrs:{"placeholder":"请输入备注"}})],1)],1)],1),_c('div',{staticStyle:{"text-align":"center","margin-top":"50px","margin-bottom":"50px"}},[_c('a-button',{attrs:{"disabled":!_vm.canSave,"type":"primary"},on:{"click":_vm.save}},[_c('img',{staticStyle:{"margin-right":"5px","height":"14px","position":"relative","top":"-1px"},attrs:{"src":require("@/assets/images/step01-btn.png")}}),_vm._v(" 可视化属性配置 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }