import Vue from "vue";
import Router from "vue-router";

import TestList from "@/views/TestList.vue";

import Step01 from "@/views/Step01.vue";
import Step02 from "@/views/Step02.vue";
import Step03 from "@/views/Step03.vue";
import Step04 from "@/views/Step04.vue";

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/test-list"
    },
    {
      path: "/test-list",
      component: TestList,
    },
    {
      path: "/step01",
      component: Step01,
    },
    {
      path: "/step02",
      component: Step02,
    },
    {
      path: "/step03",
      component: Step03,
    },
    {
      path: "/step04",
      component: Step04,
    },
    // {
    //   path: "/step05",
    //   component: step05,
    // },
  ]
})