import Vue from 'vue';
import App from './App.vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import router from "./router";
import config from "./config";
import axios from 'axios';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import utils from './utils';

Vue.use(Viewer)
Viewer.setDefaults({
    Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

Vue.prototype.$axios = axios;
Vue.prototype.$config = config;
Vue.prototype.$utils = utils;

Vue.use(Antd);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
