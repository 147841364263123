<template>
  <div>
    <a-layout id="components-layout-demo-fixed">
      <a-layout-header theme="light" v-if="!$route.query.iniframe">
        <a
          href="javascript: void(0);"
          @click="$router.replace({ path: '/test-list', query: { iniframe: $route.query.iniframe } })"
          style="cursor: pointer !important; color: rgba(0, 0, 0, 0.65)"
        >
          <a-icon type="left" style="margin-right: 20px; margin-left: 10px" />
          <h4 style="display: inline">返回可视化实验列表</h4>
        </a>
        <img
          src="@/assets/images/avatar.png"
          alt=""
          style="height: 60px; float: right"
        />
      </a-layout-header>
      <a-layout-content style="display: flex">
        <div
          style="
            width: 276px;
            background-color: #fff;
            padding: 32px 24px;
            box-shadow: 0 0px 5px #888888;
            z-index: 5;
          "
        >
          <a-steps direction="vertical" :current="step">
            <a-step
              title="基本信息"
              description="实验中的URL必须设置一个名称，并且将SDK代码段植入目标页面代码"
              style="height: 150px"
            >
            </a-step>
            <a-step
              title="可视化属性配置"
              description="在可视化编辑页面中定义要进行实验的页面元素属性和水平，用于生成不同组合的页面设计方案"
              style="height: 150px"
            >
            </a-step>
            <a-step
              title="生成实验设计"
              description="根据已定义的属性和水平自动生成多种设计方案"
              style="height: 150px"
            >
            </a-step>
            <a-step
              title="生成实验链接"
              description="根据已定义的多版本配额生成动态实验链接，或导出全部设计的截图"
              style="height: 150px"
            >
            </a-step>
          </a-steps>
        </div>
        <slot />
      </a-layout-content>
      <a-layout-footer :style="{ textAlign: 'center' }" v-if="!$route.query.iniframe">
        UI/UX Test Demo ©2022 Created by Wemark
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>

export default {
  props: {
    step: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.menu {
  display: inline-block;
  line-height: 64px;
  margin-left: 107px;
  font-size: 16px;
  color: #1c6efc;
  border-bottom: solid 2px #1c6efc;
}
</style>