<template>
  <a-input
    type="color"
    v-model="currentValue"
    @change="onChange"
    style="width: 95%"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    },
  },
  computed: {
    currentValue: {
      get(){
        return this.$utils.rgbaToHex(this.value);
      },
      set(val){
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onChange(value){
      if(!value) {
        this.$emit("change", "");
        return
      }
      this.$emit("change", value);
    },
  },
};
</script>

<style>
</style>