<template>
  <div>
    <a-modal
      class="demo-modal"
      title="查看报告"
      width="1200px"
      v-model="visible"
    >
      <div>
        <a-select v-model="versionId" style="width: 120px">
          <a-select-option
            v-for="version in versions"
            :key="version._id"
            :value="version._id"
          >
            {{ version.name }}
          </a-select-option>
        </a-select>
        <a-radio-group
          v-model="unit"
          button-style="solid"
          style="margin-left: 10px"
        >
          <a-radio-button value="day"
            >&nbsp;&nbsp;天&nbsp;&nbsp;</a-radio-button
          >
          <a-radio-button value="hour"> 小时 </a-radio-button>
          <a-radio-button value="minute"> 分钟 </a-radio-button>
        </a-radio-group>
        <a-range-picker v-model="timespan" style="margin-left: 10px" />
        <a-button type="primary" style="margin-left: 10px"> 查询 </a-button>
      </div>
      <div>
        <IEcharts :option="option" style="width: 100%; height: 300px" />
      </div>
      <div>
        <a-table :columns="columns" :data-source="data"> </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import IEcharts from "vue-echarts-v3/src/full.js";

export default {
  components: {
    IEcharts,
  },
  data() {
    return {
      visible: false,
      url: "",
      versionId: "",
      versions: [],
      unit: "day",
      timespan: [],

      option: {
        grid: {
          top: "15%",
          bottom: "15%",
          left: 0,
          right: 0,
        },
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: "line",
          },
        ],
      },

      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "Age",
          dataIndex: "age",
          key: "age",
          width: 80,
        },
        {
          title: "Address",
          dataIndex: "address",
          key: "address 1",
          ellipsis: true,
        },
        {
          title: "Long Column Long Column Long Column",
          dataIndex: "address",
          key: "address 2",
          ellipsis: true,
        },
        {
          title: "Long Column Long Column",
          dataIndex: "address",
          key: "address 3",
          ellipsis: true,
        },
        {
          title: "Long Column",
          dataIndex: "address",
          key: "address 4",
          ellipsis: true,
        },
      ],
      data: [
        {
          key: "1",
          name: "John Brown",
          age: 32,
          address: "New York No. 1 Lake Park, New York No. 1 Lake Park",
          tags: ["nice", "developer"],
        },
        {
          key: "2",
          name: "Jim Green",
          age: 42,
          address: "London No. 2 Lake Park, London No. 2 Lake Park",
          tags: ["loser"],
        },
        {
          key: "3",
          name: "Joe Black",
          age: 32,
          address: "Sidney No. 1 Lake Park, Sidney No. 1 Lake Park",
          tags: ["cool", "teacher"],
        },
      ],
    };
  },

  methods: {
    
    async show(url) {

      this.url = url;

      var res = await this.$axios.get(
        this.$config.service +
          "/api/public/pages/versions?url=" +
          encodeURIComponent(this.url)
      );

      var versions = res.data.data.map((version, index) => ({ ...version, name: "版本" + (index + 1)}));

      versions.unshift({
        _id: "",
        name: "所有版本",
      });

      this.versions = versions;
      this.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.demo-modal {
  /deep/.ant-modal-footer {
    display: none;
  }
}
</style>