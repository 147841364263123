<template>
  <StepFrame :step="1">
    <a-spin :spinning="loading" style="flex: 1" :style="{ margin: $route.query.iniframe ? '0' : '10px' }">
      <div class="frame-content">
        <div class="designer-view">
          <div class="dv-device">
            <div
              class="dv-device-item"
              :class="device == 'app' ? 'selected' : ''"
              @click="device = 'app'"
            >
              手机
            </div>
            <div
              class="dv-device-item"
              :class="device == 'pc' ? 'selected' : ''"
              @click="device = 'pc'"
            >
              电脑
            </div>
          </div>
          <div style="text-align: center">
            <iframe
              v-if="device == 'pc'"
              ref="targetFrame"
              :src="url + '#designer'"
              frameborder="0"
              width="1366"
              height="768"
              style="padding: 0; margin: 0 auto; zoom: 0.6; width: 1366px"
            ></iframe>
            <iframe
              v-if="device == 'app'"
              ref="targetFrame"
              :src="url + '#designer'"
              frameborder="0"
              width="375"
              height="677"
              style="padding: 0; margin: 0 auto; width: 375px"
            ></iframe>
          </div>
        </div>
        <div class="designer-menu">
          <div class="dm-header">
            <h3
              class="dm-header-item"
              :class="menuType == 'attrConfig' ? 'selected' : ''"
              @click="menuType = 'attrConfig'"
            >
              属性配置
            </h3>
            <h3
              class="dm-header-item"
              :class="menuType == 'attrList' ? 'selected' : ''"
              @click="menuType = 'attrList'"
            >
              属性列表
            </h3>
          </div>
          <a-divider></a-divider>
          <div v-if="menuType == 'attrConfig'" class="dmc-content">
            <div class="dmc-item">
              <div class="dmc-item-type">
                元素类型&nbsp;{{ tag && tag.tagName }}
              </div>
            </div>
            <div
              class="dmc-item"
              v-for="fieldObj in fieldVisible"
              :key="fieldObj.fieldOrder.field"
            >
              <div class="dmc-item-title">{{ fieldObj.fieldOrder.name }}</div>
              <div class="dmc-item-value">
                <a-input
                  v-if="fieldObj.fieldOrder.type == 'text'"
                  v-model="fieldObj.field.value"
                  @change="change"
                  style="width: 95%"
                />
                <EditorFieldPX
                  v-if="fieldObj.fieldOrder.type == 'px'"
                  v-model="fieldObj.field.value"
                  @change="change"
                  style="width: 95%"
                />
                <EditorFieldUpload
                  v-if="fieldObj.fieldOrder.type == 'upload'"
                  v-model="fieldObj.field.value"
                  @change="change"
                  style="width: 95%"
                />
                <EditorFieldColor
                  v-if="fieldObj.fieldOrder.type == 'color'"
                  v-model="fieldObj.field.value"
                  @change="change"
                  style="width: 95%"
                />
              </div>
            </div>
            <br />
            <div class="dmc-item">
              <a-button
                :disabled="
                  !tag ||
                  pageAttrs.some(
                    (attr) => attr.uniqueId == resultMessage.uniqueId
                  )
                "
                block
                @click="addAttr"
              >
                定义为属性
              </a-button>
            </div>
            <div class="dmc-item">
              <a-button
                :disabled="
                  !pageAttrs.some(
                    (attr) => attr.uniqueId == resultMessage.uniqueId
                  )
                "
                block
                @click="addLevel"
              >
                定义为水平
              </a-button>
            </div>
            <div class="dmc-item">
              <a-button
                :disabled="levels.length <= 0"
                block
                type="primary"
                @click="save"
              >
                完成并保存配置
              </a-button>
            </div>
          </div>
          <div v-if="menuType == 'attrList'" class="dmc-content">
            <div
              class="dmcc-attr"
              v-for="(group, index) in pageAttrGroups"
              :key="index"
            >
              <div>
                <a-input
                  @click="chooseLevel(group.levels[0])"
                  v-model="group.attr.name"
                  placeholder="请输入属性"
                  style="width: 180px; margin-bottom: 10px"
                />
                <img
                  @click="removeAttrGroup(group)"
                  src="@/assets/images/minus.png"
                  style="margin-left: 10px"
                />
              </div>
              <div v-for="(level, index) in group.levels" :key="index">
                <a-input
                  @click="chooseLevel(level)"
                  v-model="level.name"
                  placeholder="请输入水平"
                  style="margin-left: 30px; width: 150px; margin-bottom: 10px"
                />
                <img
                  @click="removeLevel(level)"
                  src="@/assets/images/minus.png"
                  style="margin-left: 10px"
                />
              </div>
            </div>
            <div class="dmc-item">
              <a-button
                :disabled="levels.length <= 0"
                block
                type="primary"
                @click="save"
                style="margin-top: 10px"
              >
                完成并保存配置
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </StepFrame>
</template>

<script>
import StepFrame from "@/componenets/StepFrame.vue";
import tagConfig from "./_tag.config";
import fieldOrders from "./_fieldorder.config";
import EditorFieldPX from "../componenets/EditorFieldPX.vue";
import EditorFieldColor from "../componenets/EditorFieldColor.vue";
import EditorFieldUpload from "../componenets/EditorFieldUpload.vue";

export default {
  components: {
    StepFrame,
    EditorFieldPX,
    EditorFieldColor,
    EditorFieldUpload,
  },
  data() {
    return {
      loading: false,

      device: "",
      name: "",
      url: "",
      menuType: "attrConfig",

      tag: null,

      message: null,

      resultMessage: {
        tagName: "",
        uniqueId: "",
        property: {},
        style: {},
        attr: {},
      },

      // 元素属性字段
      fields: [],

      // 元素属性中文信息及顺序
      fieldOrders,

      // 页面水平-元素状态
      levels: [],

      // 页面属性-元素
      pageAttrs: [],

      messageCallback: () => {},
    };
  },

  computed: {
    pageAttrGroups() {
      return this.pageAttrs.map((attr) => ({
        attr,
        levels: this.levels.filter((level) => level.uniqueId == attr.uniqueId),
      }));
    },
    fieldVisible() {
      var fieldInOrder = [];
      this.fieldOrders.forEach((fieldOrder) => {
        var targetField = this.fields.find(
          (x) => x.from == fieldOrder.from && x.key == fieldOrder.key
        );
        if (targetField) {
          fieldInOrder.push({
            fieldOrder,
            field: targetField,
          });
        }
      });
      return fieldInOrder;
    },
  },

  async mounted() {
    this.loading = true;

    var res = await this.$axios.get(
      this.$config.service +
        "/api/public/pages/query?url=" +
        encodeURIComponent(this.$route.query.url)
    );
    this.loading = false;

    if (!res.data.success) return;

    var data = res.data.data;
    this.levels = data.levels;
    this.name = data.name;
    this.pageAttrs = data.attrs;
    this.url = data.url;
    this.device = data.device || "pc";

    window.addEventListener(
      "message",
      (this.messageCallback = (e) => {
        if (!e.data) return;
        var data = JSON.parse(e.data);
        if (data.command == "iframeReady") {
          this.$refs.targetFrame.contentWindow.postMessage(
            JSON.stringify({ command: "init" }),
            "*"
          );
          return;
        }

        if (!data.command) {
          this.message = data;
          this.initFields();
          this.initMessage();
        }
      })
    );
  },
  destroyed() {
    window.removeEventListener("message", this.messageCallback);
  },

  methods: {
    /**
     * 初始化属性字段
     */
    initFields() {
      this.fields = [];

      if (!this.message) return;

      this.tag = tagConfig.find((x) => x.tagName == this.message.tagName);
      var fields = [];

      Object.keys(this.message.attr).forEach((key) => {
        if (!this.tag.attr[key]) return;
        fields.push({
          key: key,
          value: this.message.attr[key],
          type: this.tag.attr[key],
          from: "attr",
        });
      });
      Object.keys(this.message.property).forEach((key) => {
        if (!this.tag.property[key]) return;
        fields.push({
          key: key,
          value: this.message.property[key],
          type: this.tag.property[key],
          from: "property",
        });
      });

      Object.keys(this.message.style).forEach((key) => {
        if (!this.tag.style[key]) return;
        fields.push({
          key: key,
          value: this.message.style[key],
          type: this.tag.style[key],
          from: "style",
        });
      });
      this.fields = fields;
    },
    /**
     * 初始化message数据
     */
    initMessage() {
      this.resultMessage = {
        tagName: "",
        property: {},
        style: {},
        attr: {},
      };
      this.resultMessage.tagName = this.message.tagName;
      this.resultMessage.uniqueId = this.message.uniqueId;
      this.fields.forEach((field) => {
        this.resultMessage[field.from][field.key] = field.value;
      });
    },
    change() {
      this.initMessage();
      this.$refs.targetFrame.contentWindow.postMessage(
        JSON.stringify(this.resultMessage),
        "*"
      );
    },
    addAttr() {
      if (this.pageAttrs.length >= 3) {
        this.$message.warning("定义的属性不能超过3个");
        return;
      }
      this.pageAttrs.push({
        name: "属性-" + (this.pageAttrs.length + 1),
        uniqueId: this.resultMessage.uniqueId,
      });
    },
    addLevel() {
      var level = JSON.parse(JSON.stringify(this.resultMessage));
      var length = this.levels.filter(
        (x) => x.uniqueId == level.uniqueId
      ).length;
      if (length >= 3) {
        this.$message.warning("同一属性下的定义的水平不能超过3个");
        return;
      }
      level.name = "水平-" + (length + 1);
      this.levels.push(level);
    },
    chooseLevel(level) {
      this.message = JSON.parse(JSON.stringify(level));
      this.initFields();
      this.initMessage();
      this.$refs.targetFrame.contentWindow.postMessage(
        JSON.stringify(this.resultMessage),
        "*"
      );
    },
    removeLevel(level) {
      this.levels = this.levels.filter((l) => l != level);
    },
    removeAttrGroup(group) {
      this.levels = this.levels.filter(
        (level) => level.uniqueId != group.attr.uniqueId
      );
      this.pageAttrs = this.pageAttrs.filter((g) => g != group.attr);
    },
    showInputDialog(target, prop) {
      this.inputDialog.target = target;
      this.inputDialog.prop = prop;
      this.inputDialog.visible = true;
      this.inputDialog.data = this.inputDialog.target[this.inputDialog.prop];
    },
    async save() {
      this.loading = false;

      await this.$axios.post(this.$config.service + "/api/public/pages/add", {
        url: this.url,
        name: this.name,
        device: this.device,
        levels: [...this.levels],
        attrs: [...this.pageAttrs],
      });

      this.loading = true;

      this.$message.success("保存成功");
      this.$router.replace({
        path: "/step03",
        query: {
          ...this.$route.query,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.frame-content {
  flex: 1;
  display: flex;
  .designer-view {
    flex: 1;
    .dv-device {
      display: flex;
      justify-content: center;
      .dv-device-item {
        width: 80px;
        background-color: #fff;
        height: 30px;
        margin: 20px 5px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
      }
      .selected {
        color: #1890ff;
      }
    }
  }
  .designer-menu {
    width: 275px;
    background-color: #fafafa;
    .dm-header {
      padding: 0 10px;
      padding-top: 10px;
      .dm-header-item {
        line-height: 40px;
        display: inline-block;
        cursor: pointer;
        margin: 5px 10px;
        color: #989898;
      }
      .selected {
        color: #434343;
      }
    }
    .dmc-content {
      margin: 20px;
      background-color: #ffffff;
      padding: 20px 10px;
      .dmc-item {
        display: flex;
        line-height: 30px;
        margin-bottom: 20px;
        .dmc-item-type {
          width: 220px;
          height: 52px;
          border-radius: 6px 6px 6px 6px;
          opacity: 1;
          border: 1px dashed #b3b3b3;
          margin: 0 auto;
          line-height: 52px;
          text-align: center;
        }
        .dmc-item-title {
          width: 60px;
        }
        .dmc-item-value {
          flex: 1;
        }
      }
    }
  }

  /deep/.ant-divider.ant-divider-horizontal {
    width: auto;
    min-width: auto;
    margin: 0 20px;
  }
}
</style>