export default [
  ...["P", "H1", "H2", "H3", "H4", "H5", "H6", "LABEL", "SPAN", "BUTTON"].map(tagName => ({
    tagName: tagName,
    property: {
      innerText: "text",
    },
    style: {
      color: "color",
      fontSize: "px",
      position: "select",
      top: "px",
      left: "px",
      right: "px",
      bottom: "px",
    },
    attr: {},
  })),
  {
    tagName: "DIV",
    property: {
      innerText: "text",
    },
    style: {
      fontSize: "px",
      color: "color",
      backgroundColor: "color",
      position: "select",
      top: "px",
      left: "px",
      right: "px",
      bottom: "px",
    },
    attr: {},
  },
  {
    tagName: "A",
    property: {
      innerText: "text",
    },
    style: {
      color: "color",
      fontSize: "px",
      position: "select",
      top: "px",
      left: "px",
      right: "px",
      bottom: "px",
    },
    attr: {},
  },
  {
    tagName: "IMG",
    property: {
      src: "text"
    },
    style: {
      width: "px",
      height: "px",
      position: "select",
      top: "px",
      left: "px",
      right: "px",
      bottom: "px",
    },
    attr: {},
  },
  {
    tagName: "INPUT",
    property: {},
    style: {},
    attr: {
      value: "text"
    },
  },
]