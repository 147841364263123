<template>
  <div>
    <a-layout id="components-layout-demo-fixed">
      <a-layout-header v-if="!$route.query.iniframe" theme="light">
        <img src="@/assets/images/logo.png" alt="" style="height: 50px" />
        <span class="menu"> 可视化实验列表</span>
        <img
          src="@/assets/images/avatar.png"
          alt=""
          style="height: 60px; float: right"
        />
      </a-layout-header>
      <a-layout-content
        style="padding: 50px; padding-top: 30px; padding-bottom: 0px"
        :style="{
          padding: '0px',
          paddingTop: '0px'
        }"
      >
        <a-spin :spinning="loading">
          <div style="background-color: #fff; padding: 24px">
            <h3 style="display: inline-block">实验列表</h3><span style="font=size: 14px; color: #BFBFBF; margin-left: 10px;">共{{data.length}}个</span>
            <div style="width: auto; float: right">
              <a-input-search placeholder="请输入" style="width: 200px" @search="(val) => keywords = val" >
                <a-button slot="enterButton">
                  搜索
                </a-button>
              </a-input-search>
              <a-button
                type="primary"
                style="margin-left: 10px"
                @click="create"
                >创建实验</a-button
              >
            </div>
            <a-table
              :columns="columns"
              :data-source="dataVisible"
              style="margin-top: 20px"
            >
              <span slot="status" slot-scope="text, record">
                <span v-if="record.status === 1" class="status1"> 已发布 </span>
                <span v-else class="status0"> 编辑中 </span>
              </span>
              <span slot="action" slot-scope="text, record">
                <a :disabled="record.status !== 1" @click="$refs.ModalReport.show(record.url)">查看报告</a>
                <a-divider type="vertical" />
                <a @click="edit(record)">编辑</a>
                <a-divider type="vertical" />
                <a @click="delConfirm(record)">删除</a>
              </span>
            </a-table>
          </div>
        </a-spin>
      </a-layout-content>
      <a-layout-footer v-if="!$route.query.iniframe" :style="{ textAlign: 'center' }">
        UI/UX Test Demo ©2022 Created by Wemark
      </a-layout-footer>
    </a-layout>
    <ModalReport ref="ModalReport" />
  </div>
</template>

<script>
import ModalReport from "@/componenets/ModalReport.vue";

export default {
  components: {
    ModalReport
  },
  data() {
    const columns = [
      {
        title: "名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "实验ID",
        dataIndex: "_id",
        key: "_id",
      },
      {
        title: "实验类型",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "创建人",
        dataIndex: "creater",
        key: "creater",
      },
      {
        title: "状态",
        key: "status",
        scopedSlots: { customRender: "status" },
      },
      {
        title: "操作",
        width: 250,
        key: "action",
        scopedSlots: { customRender: "action" },
      },
    ];

    const data = [];
    return {

      loading: true,

      keywords: "",
      data,
      columns,

    };
  },
  async mounted() {
    var res = await this.$axios.get(
      this.$config.service + "/api/public/pages/all"
    );
    this.data = res.data.data.map((row) => ({
      creater: "UXDemo",
      type: "UI/UX可视化实验",
      ...row,
    }));
    this.loading = false
  },
  computed: {
    dataVisible() {
      return this.data.filter((x) => x.name.indexOf(this.keywords) >= 0);
    },
  },
  methods: {
    create(){
      this.$router.replace({
        path: '/step01', query: this.$route.query
      })
    },
    edit(record) {
      this.$router.replace({
        path: "/step02", 
        query: {
          ...this.$route.query,
          url: record.url
        }
      })
      // this.$router.replace("/step02?url=" + encodeURIComponent(record.url));
    },
    async delConfirm(record) {
      await this.$confirm({
        title: "删除",
        content: "确认删除此实验?",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.del(record);
        },
      });
    },
    async del(record) {
      await this.$axios.post(
        this.$config.service + "/api/public/pages/delete",
        {
          url: record.url,
        }
      );
      this.data = this.data.filter((x) => x != record);
    },
  },
};
</script>

<style scoped lang="less">
.menu {
  display: inline-block;
  line-height: 64px;
  margin-left: 107px;
  font-size: 16px;
  color: #1c6efc;
  border-bottom: solid 2px #1c6efc;
}
.status0 {
  color: #0cc126;
}
.status0:before {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #0cc126;
  opacity: 1;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  content: "";
}
.status1 {
  color: #1c6fff;
}
.status1:before {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #1c6fff;
  opacity: 1;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  content: "";
}
</style>