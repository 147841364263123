<template>
  <StepFrame :step="2">
    <a-spin class="frame-content" :spinning="loading" :style="{ margin: $route.query.iniframe ? '0' : '10px' }">
      <div class="header">
        <a-divider type="vertical" />
        <h3 class="title">全部属性</h3>
      </div>
      <div class="content">
        <div v-for="attr in data.attrs" :key="attr.uniqueId" class="content-item">
          <a-button
            @click="editView"
            type="link"
            style="float: right; padding: 0; margin-left: 10px"
          >
            编辑
          </a-button>
          <a-button
            :disabled="attr.code"
            @click="createCode(attr)"
            type="link"
            style="float: right; padding: 0; margin-left: 10px"
          >
            埋码
          </a-button>
          <div style="clear: both"></div>
          <div class="ci-content">
            <img class="cic-del" src="@/assets/images/deleteAttr.png" alt="" @click="removeAttr(attr)" />
            <div class="cic-left">
              属性
              <div>
                <a-input v-model="attr.name" placeholder="请输入" style="width: 70%" />
              </div>
            </div>
            <div class="cic-right">
              水平
              <div style="margin-bottom: 10px" v-for="(level, key) in data.levels.filter((level) => level.uniqueId == attr.uniqueId)" :key="key">
                <a-input v-model="level.name" placeholder="请输入" style="width: 60%; margin-right: 5px;" />
                <a-button
                  @click="view(level, data)"
                  type="link"
                  style="padding: 0 5px;"
                >
                  预览
                </a-button>
                <a-button
                  @click="removeLevel(level)"
                  type="link"
                  style="padding: 0 5px;"
                >
                  删除
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin-top: 50px; margin-bottom: 50px;">
        <a-button type="primary" @click="save"> <a-icon type="profile" /> 生成设计 </a-button>
      </div>
    </a-spin>
    <a-modal
      class="demo-modal"
      title="预览"
      width="1200px"
      v-model="previewDialog.visible"
    >
      <a-spin :spinning="previewDialog.loading" v-if="previewDialog.visible" style="text-align: center;">
        <iframe
          v-if="previewDialog.device == 'pc'"
          ref="source"
          frameborder="0"
          width="1100px"
          height="650"
        ></iframe>
        <iframe
          v-if="previewDialog.device == 'app'"
          ref="source"
          frameborder="0"
          width="375"
          height="677"
        ></iframe>
      </a-spin>
    </a-modal>
  </StepFrame>
</template>

<script>
import StepFrame from "@/componenets/StepFrame.vue";
export default {
  components: {
    StepFrame,
  },
  data(){
    return {
      loading: true,
      data: {
        attrs: [],
        levels: [],
      },
      previewDialog: {
        visible: false,
        loading: false,
        device: "",
        level: null
      },

      messageCallback: () => {}
    };
  },
  async mounted() {
    this.loading = true
    var res = await this.$axios.get(
      this.$config.service + "/api/public/pages/query?url=" +
        encodeURIComponent(this.$route.query.url)
    );
    this.loading = false
    this.data = res.data.data;
    
    window.addEventListener("message", this.messageCallback = (e) => {
      if (!e.data) return;
      var data = JSON.parse(e.data);
      if (data.command == "iframeReady") {
        this.$refs.source.contentWindow.postMessage(
          JSON.stringify({
            command: "preview",
            levels: [this.previewDialog.level],
          }),
          "*"
        );
        this.previewDialog.loading = false
        return;
      }
    });
  },
  destroyed(){
    window.removeEventListener("message", this.messageCallback)
  },
  methods: {

    view(level, data){
      this.previewDialog.visible = true;
      this.previewDialog.device = data.device;
      this.previewDialog.loading = true
      this.previewDialog.level = level
      this.$nextTick(() => {
        this.$refs.source.src = data.url + "#designer";
        // window.addEventListener("message", this.messageCallback = (e) => {
        //   if (!e.data) return;
        //   var data = JSON.parse(e.data);
        //   if (data.command == "iframeReady") {
        //     console.log("iframeReady" + JSON.stringify(level))
        //     this.$refs.source.contentWindow.postMessage(
        //       JSON.stringify({
        //         command: "preview",
        //         levels: [level],
        //       }),
        //       "*"
        //     );
        //     this.previewDialog.loading = false
        //     return;
        //   }
        // });
      })
    },
    
    async save() {
      
      this.loading = true

      await this.$axios.post(this.$config.service + "/api/public/pages/add", {
        url: decodeURIComponent(this.$route.query.url),
        device: this.data.device,
        name: this.data.name,
        levels: [...this.data.levels],
        attrs: [...this.data.attrs],
      });

      this.loading = false

      this.$message.success("保存成功");
      this.$router.replace({
        path: "/step04",
        query: {
          ...this.$route.query,
        },
      });
    },

    /**
     * 埋码
     */
    createCode(attr) {
      this.$set(attr, "code", true);
    },

    removeLevel(level) {
      this.data.levels = this.data.levels.filter((l) => l != level);
    },
    removeAttr(attr) {
      this.data.levels = this.data.levels.filter(
        (level) => level.uniqueId != attr.uniqueId
      );
      this.data.attrs = this.data.attrs.filter((g) => g != attr);
    },

    editView(){
      this.$router.replace({
        path: "/step02",
        query: {
          ...this.$route.query,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.frame-content {
  flex: 1;
  margin: 10px;
  margin-bottom: 0;
  background-color: #fff;
  .header {
    padding: 20px;
    /deep/.ant-divider.ant-divider-vertical {
      width: 4px;
      height: 16px;
      background-color: #1890ff !important;
      margin-left: 0;
    }
    .title {
      display: inline-block;
    }
  }
  .content {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .content-item {
      width: 49%;
      margin-bottom: 10px;
      .ci-content {
        height: 180px;
        background-color: #fafafa;
        display: flex;
        line-height: 30px;
        padding: 0 10px;
        position: relative;
        .cic-del {
          position: absolute;
          right: 0;
          top: 0;
        }
        .cic-left {
          flex: 1;
          padding: 10px;
        }
        .cic-right {
          flex: 1;
          padding: 10px;
        }
      }
    }
  }
}
.demo-modal{

  /deep/.ant-modal-footer{
    display: none;
  }
}
</style>