<template>
  <a-input-number
    :default-value="defaultValue"
    :formatter="(value) => `${value}px`"
    :parser="(value) => value.replace(/[px]/g, '')"
    @change="onChange"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "px"
    },
  },
  computed: {
    defaultValue(){
      if(!this.value) return null;
      return Number(this.value.replace("px", ""))
    },
  },
  methods: {
    onChange(value){
      if(!value) {
        this.$emit("input", "");
        this.$emit("change", "");
        return
      }
      this.$emit("input", value + "px");
      this.$emit("change", value + "px");
    },
  },
};
</script>

<style>
</style>