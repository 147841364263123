<template>
  <StepFrame :step="3">
    <div>
      <iframe
        v-if="device == 'pc'"
        ref="source"
        frameborder="0"
        width="1366"
        height="768"
        style="position: absolute; z-index: -1; visibility: hidden"
      ></iframe>
      <iframe
        v-if="device == 'app'"
        ref="source"
        frameborder="0"
        width="375"
        height="677"
        style="position: absolute; z-index: -1; visibility: hidden"
      ></iframe>
    </div>
    <div class="frame-content">
      <div class="header">
        <a-divider type="vertical" />
        <h3 class="title">全部设计版本</h3>
      </div>
      <div class="content">
        <div class="content-top">
          <label for="">实验用URL</label>
          <a-input-group
            compact
            style="
              display: inline-block;
              width: 500px;
              margin-left: 20px;
              position: relative;
              top: -5px;
            "
          >
            <a-input v-model="url" disabled style="width: 400px" />
            <a-button
              @click="copyfn(url)"
              :disabled="versions.length > images.length"
              >复制链接</a-button
            >
          </a-input-group>
          <a-button
            v-if="$route.query.iniframe"
            :disabled="versions.length > images.length"
            @click="save"
            type="primary"
            style="float: right; margin-top: 15px; margin-left: 20px"
          >
            保存创作</a-button
          >
          <a-button
            :disabled="versions.length > images.length"
            @click="exportImages"
            type="primary"
            style="float: right; margin-top: 15px; margin-left: 20px"
          >
            <img
              src="@/assets/images/export.png"
              alt=""
              style="height: 14px; margin-right: 5px"
            />
            导出图片</a-button
          >
          <a-progress
            :percent="Math.round((images.length / versions.length) * 100)"
            style="width: 200px; float: right; line-height: 60px"
          />
        </div>
        <div class="content-list">
          <viewer v-for="(image, key) in images" :key="key" class="cl-item">
            <img
              ref="images"
              :src="image"
              alt=""
              style="max-width: 100%; max-height: 100%"
            />
            <div style="text-align: left; line-height: 40px">
              版本{{ key + 1 }}
            </div>
          </viewer>
        </div>
      </div>
    </div>
  </StepFrame>
</template>

<script>
import StepFrame from "@/componenets/StepFrame.vue";
import JSZip from "jszip";
import { saveAs } from "file-saver";
export default {
  components: {
    StepFrame,
  },
  data() {
    return {
      url: "",

      title: "",

      device: "pc",
      images: [],
      versions: [{}],

      messageCallback: () => {},
    };
  },

  async mounted() {
    this.url = decodeURIComponent(this.$route.query.url);

    var res = await this.$axios.get(
      this.$config.service +
        "/api/public/pages/generateversions?url=" +
        encodeURIComponent(this.$route.query.url)
    );
    this.device = res.data.data.device;

    this.$nextTick(() => {

      this.$refs.source.src = decodeURIComponent(this.$route.query.url) + '#designer';

      this.versions = res.data.data.versions;
      this.title = res.data.data.name;

      window.addEventListener(
        "message",
        (this.messageCallback = (e) => {
          if (!e.data) return;
          var data = JSON.parse(e.data);
          if (data.command == "iframeReady") {
            if (this.versions.length <= this.images.length) {
              this.complete();
              return;
            }
            this.$refs.source.contentWindow.postMessage(
              JSON.stringify({
                command: "capture",
                levels: this.versions[this.images.length],
              }),
              "*"
            );
            return;
          }
          if (data.command == "captureCallback") {
            this.images.push(data.dataUrl);
            this.$refs.source.src = "";
            setTimeout(
              () =>
                (this.$refs.source.src = decodeURIComponent(
                  this.$route.query.url
                ) + '#designer'),
              500
            );
            return;
          }
        })
      );
    });
  },

  destroyed() {
    window.removeEventListener("message", this.messageCallback);
  },
  methods: {
    async complete() {

      var versions = JSON.parse(JSON.stringify(this.versions));

      await this.$axios.post(
        this.$config.service +
          "/api/public/pages/complete?url=" +
          encodeURIComponent(this.$route.query.url),
        {
          url: this.$route.query.url,
          versions: versions
        }
      );
    },
    addCode() {
      this.$router.push(
        "/step04?url=" + encodeURIComponent(this.$route.query.url)
      );
    },
    async exportImages() {
      var zip = new JSZip();

      this.images.forEach((image, idx) => {
        zip.file(
          new Date().getTime() + "-" + idx + ".png",
          image.replace("data:image/png;base64,", ""),
          { base64: true }
        );
      });

      zip.generateAsync({ type: "blob" }).then(function (content) {
        // see FileSaver.js
        saveAs(content, new Date().getTime() + ".zip");
      });
    },

    copyfn(text) {
      var copyInput = document.createElement("input");
      copyInput.setAttribute("value", text);
      document.body.appendChild(copyInput);
      copyInput.select();
      document.execCommand("copy");
      document.body.removeChild(copyInput);
    },

    save() {
      setTimeout(() => {
        this.$router.replace({ path: '/test-list', query: { iniframe: this.$route.query.iniframe } })
      })
      window.parent.postMessage(JSON.stringify({
          command: "ux-test-submit",
          data: {
            url: this.url,
            title: this.title
          }
      }), '*');
    },
  },
};
</script>

<style lang="less" scoped>
.frame-content {
  flex: 1;
  margin: 10px;
  margin-bottom: 0;
  background-color: #fff;

  .header {
    padding: 20px;
    /deep/.ant-divider.ant-divider-vertical {
      width: 4px;
      height: 16px;
      background-color: #1890ff !important;
      margin-left: 0;
    }
    .title {
      display: inline-block;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .content-top {
      margin: 0 20px;
      padding: 0 20px;
      line-height: 60px;
      height: 60px;
      background: #fafafa;
      border-radius: 6px 6px 0px 0px;
      opacity: 1;
      border: 1px solid #d9d9d9;
      vertical-align: middle;
    }
    .content-list {
      margin: 0 20px;
      padding: 20px;
      opacity: 1;
      border: 1px solid #d9d9d9;
      border-top: 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      min-height: 500px;
      .cl-item {
        width: 235px;
        height: 274px;
        opacity: 1;
        background-color: #fafafa;
        margin-bottom: 30px;
        margin-right: 10px;
        text-align: center;
        line-height: 254px;
        padding: 10px;
      }
    }
  }
}
</style>