export default [
  {
    name: "链接",
    from: "property",
    key: "src",
    type: "upload",
  },
  {
    name: "内容",
    from: "property",
    key: "innerText",
    type: "text",
  },
  {
    name: "字号",
    from: "style",
    key: "fontSize",
    type: "px",
  },
  {
    name: "颜色",
    from: "style",
    key: "color",
    type: "color",
  },
  {
    name: "背景色",
    from: "style",
    key: "backgroundColor",
    type: "color",
  },
  {
    name: "宽度",
    from: "style",
    key: "width",
    type: "px",
  },
  {
    name: "高度",
    from: "style",
    key: "height",
    type: "px",
  },
];