<template>
  <StepFrame :step="0">
    <a-spin class="frame-content" :spinning="loading" :style="{ margin: $route.query.iniframe ? '0' : '10px' }">
      <div class="header">
        <a-divider type="vertical" />
        <h3 class="title">基本信息</h3>
      </div>
      <div class="item">
        <h4 class="sub-title">实验类型</h4>
        <div class="content">
          <div class="type-item1">
            <img src="@/assets/images/type-item1.png" width="80" height="80" />
            <div style="flex: 1">
              <h4>UI/UX可视化实验</h4>
              <span>
                通过可视化编辑的方式修改已有页面元素，无需代码编程即可快速进行文本、图片的替换和布局样式的调整，并根据自定义的属性和水平生成全部可能的组合页面。目前支持Web和H5页面
              </span>
            </div>
          </div>
          <div class="type-item2">
            <img src="@/assets/images/type-item2.png" width="80" height="80" />
            <div style="flex: 1">
              <h4>产品原型可视化实验</h4>
              <span>
                通过可视化编辑的方式修改已有页面元素，无需代码编程即可快速进行文本、图片的替换和布局样式的调整，并根据自定义的属性和水平生成全部可能的组合页面。目前支持Web和H5页面
              </span>
            </div>
          </div>
        </div>
      </div>
      <a-form
        :form="form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 12 }"
        labelAlign="left"
        style="padding: 0 24px"
      >
        <a-divider></a-divider>
        <a-form-item label="实验名称">
          <a-input-group compact style="display: inline-block; width: auto">
            <a-input
              style="width: 300px"
              v-decorator="[
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入实验名称',
                    },
                  ],
                },
              ]"
              placeholder="请输入实验名称"
            />
          </a-input-group>
        </a-form-item>
        <a-divider></a-divider>
        <a-form-item label="实验URL">
          <a-input-group compact style="display: inline-block; width: auto">
            <a-input
              style="width: 540px"
              v-decorator="[
                'url',
                {
                  rules: [
                    { required: true, message: '请输入实验URL' },
                    { pattern: /https?:\/\/.+/, message: '请输入正确的URL格式' },
                    { validator: checkUrlService },
                  ],
                },
              ]"
              placeholder="请输入实验URL"
            /><a-button :disabled="!form.getFieldValue('url')" @click="checkUrl"
              >解析</a-button
            >
            <br>
            <span v-if="canSave" style="color: #52c41a; margin-top: 2px;">解析成功</span>
          </a-input-group>
        </a-form-item>
        <a-divider></a-divider>
        <a-form-item label="备注（选填）">
          <a-input-group compact style="display: inline-block; width: auto">
            <a-textarea
              style="width: 600px"
              v-decorator="[
                'remark',
                {
                  rules: [],
                },
              ]"
              placeholder="请输入备注"
            />
          </a-input-group>
        </a-form-item>
      </a-form>
      <div style="text-align: center; margin-top: 50px; margin-bottom: 50px">
        <a-button :disabled="!canSave" type="primary" @click="save">
          <img src="@/assets/images/step01-btn.png" style="margin-right: 5px; height: 14px; position: relative; top: -1px;"> 可视化属性配置 </a-button>
      </div>
    </a-spin>
  </StepFrame>
</template>

<script>
import StepFrame from "@/componenets/StepFrame.vue";
export default {
  components: {
    StepFrame,
  },
  data() {
    return {

      loading: false,

      form: this.$form.createForm(this, {
        name: "",
        url: "",
        remark: "",
      }),
      canSave: false
    };
  },
  methods: {
    checkUrl() {
      this.form.validateFields(["url"], { force: true });
    },
    async checkUrlService(rule, value, callback) {
      
      this.canSave = false;
      if (!value) return;

      this.loading = true
      var res = await this.$axios.get(
        this.$config.service +
          "/api/public/pages/checkurl?url=" +
          encodeURIComponent(value)
      );
      this.loading = false
      if (!res.data.success) {
        callback(new Error(res.data.msg));
        return;
      }
      this.canSave = true;
      callback();
    },
    async save() {
      this.loading = true
      var res = await this.form.validateFields();
      await this.$axios.post(this.$config.service + "/api/public/pages/add", {
        name: res.name,
        url: res.url,
        device: "app",
        levels: [],
        attrs: [],
      });
      this.loading = false

      this.$router.replace({
        path: "/step02",
        query: {
          ...this.$route.query,
          url: res.url
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.frame-content {
  flex: 1;
  margin: 10px;
  margin-bottom: 0;
  background-color: #fff;
  .header {
    padding: 20px;
    /deep/.ant-divider.ant-divider-vertical {
      width: 4px;
      height: 16px;
      background-color: #1890ff !important;
      margin-left: 0;
    }
    .title {
      display: inline-block;
    }
  }
  .item {
    .sub-title {
      display: inline-block;
      min-width: 120px;
      line-height: 20px;
      padding: 0 20px;
    }
    .content {
      display: flex;
      padding: 10px 20px;
      .type-item1 {
        flex: 1;
        padding: 20px;
        background: #f9fcff;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        border: 1px solid #1c6fff;
        display: flex;
      }
      .type-item2 {
        flex: 1;
        padding: 20px;
        background: #f9fcff;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        margin-left: 10px;
        display: flex;
      }
      img {
        margin-top: 10px;
        margin-right: 10px;
      }
    }
  }

  /deep/.ant-divider.ant-divider-horizontal {
    width: auto;
    min-width: auto;
    margin: 24px 0;
  }
}
</style>