<template>
  <a-upload
    name="file"
    :multiple="false"
    :action="$config.service + '/api/public/images/upload'"
    :showUploadList="false"
    :headers="headers"
    @change="handleChange"
  >
    <a-button type="link"><a-icon type="upload" />上传</a-button>
  </a-upload>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      headers: {},
    };
  },
  methods: {
    handleChange(info) {
      // if (info.file.status !== "uploading") {
      //   console.log(info.file, info.fileList);
      // }
      if (info.file.status === "done") {
        this.success(
          this.$config.service + "/api/public/images/fetch?name=" +
            info.file.response.data
        );
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    success(value) {
      if (!value) {
        this.$emit("input", "");
        this.$emit("change", "");
        return;
      }
      this.$emit("input", value);
      this.$emit("change", value);
    },
  },
};
</script>

<style>
</style>